import AWS from 'aws-sdk';

// Configure AWS with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'us-east-1' // e.g., 'us-east-1'
});

// Create an S3 instance
const s3 = new AWS.S3();

export { s3 };