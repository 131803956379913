import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { s3 } from './aws'; // Ensure AWS SDK is properly configured and imported
import * as Realm from 'realm-web';

// Define the restaurant codes that do not receive software updates
const NO_UPDATE_RESTAURANT_CODES = ['55', '0'];

// Helper function to ensure MongoDB entry exists for S3 item
const ensureMongoDBEntry = async (s3Key, restaurantCodeNumber) => {
  const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
  const user = await app.logIn(Realm.Credentials.anonymous());
  const mongodb = user.mongoClient('mongodb-atlas');
  const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

  const restaurant = await restaurantsCollection.findOne({ restaurantCodeNumber });
  if (!restaurant) {
    console.error(`Restaurant not found for code: ${restaurantCodeNumber}`);
    return;
  }

  const existingMenuItem = restaurant.menuItems.find(item => item.keyNameForS3 === s3Key);
  if (!existingMenuItem) {
    const parts = s3Key.split('_');
    const newMenuItem = {
      menuItemName: parts[3].replace(/-/g, ' '),
      showOrHide: 'show',
      keyNameForS3: s3Key,
      fileType: s3Key.split('.').pop().toLowerCase(),
      category: "Uncategorized",
      sequenceWithinCategory: parseInt(parts[2]) || 0,
      sequenceWithinIdenticalMenuItemName: 1,
    };

    await restaurantsCollection.updateOne(
      { restaurantCodeNumber },
      { 
        $push: { menuItems: newMenuItem },
        $set: { updatedAt: new Date() }
      }
    );
    console.log(`Created new MongoDB entry for S3 key: ${s3Key}`);
  }
};

function App() {
  const [inputPrefix, setInputPrefix] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameOfRestaurant, setNameOfRestaurant] = useState('');
  const [activeImage, setActiveImage] = useState(null);
  const [editEnabled, setEditEnabled] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [hideEverything, setHideEverything] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const fileInputRef = useRef(null);
  const uploadFormRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showHidden, setShowHidden] = useState(false);
  const [currentImageIndices, setCurrentImageIndices] = useState({});
  const [isNoUpdateRestaurant, setIsNoUpdateRestaurant] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editingCategories, setEditingCategories] = useState(false);
  const [tempCategories, setTempCategories] = useState({});
  const [tempCategoryOrders, setTempCategoryOrders] = useState({});
  const [tempNames, setTempNames] = useState({});
  const [newMediaCategory, setNewMediaCategory] = useState('');
  const [newMediaCategoryOrder, setNewMediaCategoryOrder] = useState('');
  const [showNewRestaurantForm, setShowNewRestaurantForm] = useState(false);
  const [newRestaurantData, setNewRestaurantData] = useState({
    restaurantCodeNumber: '',
    restaurantName: '',
    category: 'Uncategorized',
    menuItemName: '',
    file: null,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

// Function to highlight matching text
const highlightMatch = (text, highlight) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts.map((part, i) => 
        regex.test(part) ? <mark key={i} className="cool-highlight">{part}</mark> : <span key={i}>{part}</span>
      )}
    </span>
  );
};
  // Improved filtering function
  const filteredCategories = categories.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAddPhotoClick = () => {
    setShowUploadForm(!showUploadForm);
  };

  const videoRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const hasC = params.has('c');
    const hasO = params.has('o');
    if (!hasC && !hasO) {
     // setHideEverything(true);
    }
    if (hasC) {
      const code = params.get('c');
      setInputPrefix(code);
      if (code) {
        retrieveAllImagesFromARestaurant(code);
      }
    }
    if (hasO && params.get('o') === 's') {
      setEditEnabled(true);
      setShowHidden(true);
    }
    setHideForm(hasC && !hasO);
  }, []);

  
  const retrieveAllImagesFromARestaurant = async (prefixIsThisF = '1_') => {
    setLoading(true);
    console.log('Starting to retrieve images for restaurant:', prefixIsThisF);

    const prefixIsThis = prefixIsThisF[prefixIsThisF.length - 1] === '_' ? prefixIsThisF : prefixIsThisF + '_';
    const listParams = { Bucket: 'picturemenu', Prefix: prefixIsThis };

    try {
      const data = await s3.listObjectsV2(listParams).promise();
      console.log('S3 data retrieved:', data.Contents.length, 'items');

      if (data.Contents.length > 0) {
        const firstKey = data.Contents[0].Key;
        const restaurantCode = firstKey.split('_')[0];
        setIsNoUpdateRestaurant(NO_UPDATE_RESTAURANT_CODES.includes(restaurantCode));
      }

      // Fetch restaurant data from MongoDB
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      let user;
      try {
        user = await app.logIn(Realm.Credentials.anonymous());
      } catch (error) {
        console.error('Failed to authenticate with MongoDB Realm:', error);
        throw new Error('Authentication failed. Please check your Realm configuration.');
      }

      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');
      let restaurant = await restaurantsCollection.findOne({ restaurantCodeNumber: parseInt(prefixIsThisF) });

      if (!restaurant) {
        console.warn(`Restaurant not found for code: ${prefixIsThisF}`);
        setNameOfRestaurant(`Restaurant ${prefixIsThisF}`);
      } else {
        setNameOfRestaurant(restaurant.restaurantName);
        console.log('Restaurant data retrieved:', restaurant.restaurantName);

        // Check if categories exist, if not, add them
        if (!restaurant.categories || restaurant.categories.length === 0) {
          console.log('Categories not found, adding default category');
          const updateResult = await restaurantsCollection.updateOne(
            { restaurantCodeNumber: parseInt(prefixIsThisF) },
            { 
              $set: { 
                categories: [{ category: "Uncategorized", sequenceOrder: 9999 }],
                updatedAt: new Date()
              }
            }
          );
          console.log('Update result:', updateResult);
          restaurant = await restaurantsCollection.findOne({ restaurantCodeNumber: parseInt(prefixIsThisF) });
        }
      }

      for (const object of data.Contents) {
        await ensureMongoDBEntry(object.Key, parseInt(prefixIsThisF));
      }

      const imagePromises = data.Contents.map(async (object) => {
        const fileExtension = object.Key.split('.').pop().toLowerCase();
        let mimeType;
        
        switch (fileExtension) {
          case 'mp4':
          case 'mov':
          case 'avi':
          case 'wmv':
          case 'flv':
          case 'mkv':
          case 'webm':
            mimeType = `video/${fileExtension}`;
            break;
          case 'jpg':
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          case 'gif':
            mimeType = 'image/gif';
            break;
          default:
            mimeType = 'application/octet-stream';
        }

        let src;
        if (mimeType.startsWith('video')) {
          src = s3.getSignedUrl('getObject', {
            Bucket: 'picturemenu',
            Key: object.Key,
            Expires: 3600 // URL valid for 1 hour
          });
        } else {
          const getObjectParams = { Bucket: 'picturemenu', Key: object.Key };
          const { Body, ContentType } = await s3.getObject(getObjectParams).promise();
          mimeType = ContentType || mimeType; // Use the ContentType from S3 if available
          src = `data:${mimeType};base64,${Body.toString('base64')}`;
        }

        let menuItem, category;
        if (restaurant) {
          menuItem = restaurant.menuItems.find(item => item.keyNameForS3 === object.Key);
          if (menuItem) {
            category = restaurant.categories.find(cat => cat.category === menuItem.category) || 
                       { category: "Uncategorized", sequenceOrder: 9999 };
          }
        }

        if (!menuItem || !menuItem.sequenceWithinIdenticalMenuItemName) {
          // If menuItem doesn't exist or doesn't have a valid sequenceWithinIdenticalMenuItemName,
          // we need to update it in MongoDB
          const existingMenuItems = restaurant.menuItems.filter(item => 
            item.menuItemName === menuItem.menuItemName && 
            item.sequenceWithinIdenticalMenuItemName
          );
          const nextSequence = existingMenuItems.length + 1;

          await restaurantsCollection.updateOne(
            { restaurantCodeNumber: parseInt(prefixIsThisF), "menuItems.keyNameForS3": object.Key },
            { 
              $set: { 
                "menuItems.$.sequenceWithinIdenticalMenuItemName": nextSequence,
                updatedAt: new Date()
              }
            }
          );

          if (!menuItem) {
            console.warn(`Menu item not found for key: ${object.Key}`);
            // Extract information from the S3 key
            const parts = object.Key.split('_');
            menuItem = {
              menuItemName: parts[3].replace(/-/g, ' '),
              sequenceWithinCategory: parseInt(parts[2]) || 0,
              category: "Uncategorized",
              showOrHide: 'show',
              sequenceWithinIdenticalMenuItemName: nextSequence
            };
            category = { category: "Uncategorized", sequenceOrder: 9999 };
          } else {
            menuItem.sequenceWithinIdenticalMenuItemName = nextSequence;
          }
        }

        console.log('Processed item:', menuItem.menuItemName, 'Category:', category.category, 'Sequence:', menuItem.sequenceWithinCategory);

        return { 
          key: object.Key, 
          src, 
          type: mimeType, 
          sequenceWithinCategory: menuItem.sequenceWithinCategory,
          name: menuItem.menuItemName, 
          fullName: menuItem.menuItemName,
          category: menuItem.category,
          categoryOrder: category ? category.sequenceOrder : 9999,
          showOrHide: menuItem.showOrHide, // Change this line
          sequenceWithinIdenticalMenuItemName: menuItem.sequenceWithinIdenticalMenuItemName || 0
        };
      });

      const loadedImages = (await Promise.all(imagePromises)).filter(Boolean);
      
      console.log('Grouping and sorting images...');
      // Group images by name
      const groupedImages = loadedImages.reduce((acc, image) => {
        if (!acc[image.name]) {
          acc[image.name] = [];
        }
        acc[image.name].push(image);
        return acc;
      }, {});

      // Sort images within each group by sequenceWithinIdenticalMenuItemName
      const sortedGroups = Object.entries(groupedImages).map(([name, group]) => {
        return [name, group.sort((a, b) => a.sequenceWithinIdenticalMenuItemName - b.sequenceWithinIdenticalMenuItemName)];
      });

      sortedGroups.sort(([, a], [, b]) => {
        const minSeqA = Math.min(...a.map(item => item.sequenceWithinCategory));
        const minSeqB = Math.min(...b.map(item => item.sequenceWithinCategory));
        if (a[0].categoryOrder !== b[0].categoryOrder) {
          return a[0].categoryOrder - b[0].categoryOrder;
        }
        return minSeqA - minSeqB;
      });

      // Flatten the sorted groups back into an array
      const sortedImages = sortedGroups.flatMap(([, group]) => group);

      console.log('Sorted images:', sortedImages.map(img => `${img.category} (${img.categoryOrder}) - ${img.name} (${img.rank})`));

      setImages(sortedImages);
      
      // Set categories state
      if (restaurant && restaurant.categories) {
        const sortedCategories = restaurant.categories
          .sort((a, b) => a.sequenceOrder - b.sequenceOrder)
          .map(cat => ({ name: cat.category, order: cat.sequenceOrder }));
        setCategories(sortedCategories);
        console.log('Categories set:', sortedCategories);
      } else {
        setCategories([{ name: "Uncategorized", order: 9999 }]);
        console.log('Default category set');
      }

    } catch (err) {
      console.error('Error:', err);
      alert('Failed to load images: ' + err.message);
    }

    setLoading(false);
    console.log('Image retrieval complete');
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await retrieveAllImagesFromARestaurant(inputPrefix);
    setLoading(false);
  };

  const handleDrop = async (event, key) => {
    if (!editEnabled) return;

    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const newKey = key.replace(/\.[^.]+$/, `.${fileExtension}`);

      try {
        await s3.deleteObject({
          Bucket: 'picturemenu',
          Key: key
        }).promise();

        const params = {
          Bucket: 'picturemenu',
          Key: newKey,
          Body: file,
          ContentType: file.type || 'application/octet-stream'
        };
        await s3.putObject(params).promise();

        // Update MongoDB
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        const user = await app.logIn(Realm.Credentials.anonymous());
        const mongodb = user.mongoClient('mongodb-atlas');
        const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

        await restaurantsCollection.updateOne(
          { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key },
          { 
            $set: { 
              "menuItems.$.keyNameForS3": newKey,
              "menuItems.$.fileType": fileExtension,
              updatedAt: new Date()
            }
          }
        );

        alert('Media replaced successfully');
        await retrieveAllImagesFromARestaurant(inputPrefix);
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to replace media: ' + err.message);
      }
    }
  };



  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const handleNameChange = (event, key) => {
    const newImages = images.map(image =>
      image.key === key ? { ...image, fullName: event.target.value } : image
    );
    setImages(newImages);
  };

  const handleSaveName = async (key) => {
    const parts = key.split('_');
    parts[3] = images.find(img => img.key === key).fullName.replace(/ /g, '-');
    const newKey = parts.join('_');

    if (key !== newKey) {
      try {
        await updateKeyNameInS3AndMongoDB(key, newKey, parseInt(inputPrefix));


        

        alert('Name changed successfully');
        await retrieveAllImagesFromARestaurant(inputPrefix);
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to change name: ' + err.message);
      }
    }
  };

  const handleSaveRank = async (key) => {
    const parts = key.split('_');
    parts[2] = images.find(img => img.key === key).rank;
    const newKey = parts.join('_');
    if (key !== newKey) {
      const copyParams = {
        Bucket: 'picturemenu',
        CopySource: `picturemenu/${key}`,
        Key: newKey
      };
      const deleteParams = {
        Bucket: 'picturemenu',
        Key: key
      };
      try {
        await s3.copyObject(copyParams).promise();
        await s3.deleteObject(deleteParams).promise();
        alert('Rank changed successfully');
        await retrieveAllImagesFromARestaurant(inputPrefix);
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to change rank: ' + err.message);
      }
    }
  };

  const handleRankChange = (event, key) => {
    const newImages = images.map(image =>
      image.key === key ? { ...image, rank: event.target.value } : image
    );
    setImages(newImages);
  };

  const handleDeleteImage = async (key) => {
    try {
      await s3.deleteObject({
        Bucket: 'picturemenu',
        Key: key
      }).promise();

      // Remove from MongoDB
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix) },
        { 
          $pull: { menuItems: { keyNameForS3: key } },
          $set: { updatedAt: new Date() }
        }
      );

      alert('Image deleted successfully');
      await retrieveAllImagesFromARestaurant(inputPrefix);
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to delete image: ' + err.message);
    }
  };


  const handleNewMediaUpload = async (event) => {
    event.preventDefault();
    const file = event.target.newMediaFile.files[0];
    const menuItemName = event.target.menuItemName.value;
    const category = event.target.category.value || "Uncategorized";
    const sequenceWithinCategory = parseInt(event.target.sequenceWithinCategory.value) || 0;
    const sequenceWithinIdenticalMenuItemName = parseInt(event.target.sequenceWithinIdenticalMenuItemName.value) || 0;
    const categoryNewSequence = parseInt(event.target.categoryNewSequence.value) || 0;
  
    if (file && menuItemName) {
      const extension = file.name.split('.').pop().toLowerCase();
      const newKey = `${inputPrefix}_${nameOfRestaurant.replace(/ /g, '-')}_${sequenceWithinCategory}_${menuItemName.replace(/ /g, '-')}_CN_${category.replace(/ /g, '-')}.${extension}`;
  
      const params = {
        Bucket: 'picturemenu',
        Key: newKey,
        Body: file,
        ContentType: file.type
      };
  
      try {
        // Upload to S3
        await s3.putObject(params).promise();
  
        // Update MongoDB
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        const user = await app.logIn(Realm.Credentials.anonymous());
        const mongodb = user.mongoClient('mongodb-atlas');
        const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');
  
        const newMenuItem = {
          menuItemName,
          showOrHide: 'show',
          keyNameForS3: newKey,
          fileType: file.type,
          category,
          sequenceWithinCategory,
          sequenceWithinIdenticalMenuItemName,
        };
  
        // First, update or insert the category
        await restaurantsCollection.updateOne(
          { 
            restaurantCodeNumber: parseInt(inputPrefix),
            "categories.category": { $ne: category }
          },
          { 
            $addToSet: { 
              categories: {
                category: category,
                sequenceOrder: categoryNewSequence
              }
            }
          },
          { upsert: true }
        );
  
        // Then, update the restaurant document with the new menu item
        const updateResult = await restaurantsCollection.updateOne(
          { restaurantCodeNumber: parseInt(inputPrefix) },
          { 
            $push: { menuItems: newMenuItem },
            $set: { updatedAt: new Date() },
            $setOnInsert: { 
              createdAt: new Date(),
              categories: [{
                category: "Uncategorized",
                sequenceOrder: 10,
              }]
            }
          },
          { upsert: true }
        );
  
        console.log('Update result:', updateResult);
  
        alert('New menu item added successfully');
        await retrieveAllImagesFromARestaurant(inputPrefix);
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to add new menu item: ' + err.message);
      }
    } else {
      alert('Please provide both a menu item name and a file.');
    }
  };

  const handleUserUpload = async (event) => {
    event.preventDefault();
    const file = event.target.userMediaFile.files[0];
    const selectedMenuItemKey = event.target.menuItemSelect.value;

    if (file && (file.type.startsWith('image/') || file.type.startsWith('video/')) && selectedMenuItemKey) {
      // Check video duration and format
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        video.preload = 'metadata';

        const durationPromise = new Promise((resolve) => {
          video.onloadedmetadata = () => resolve(video.duration);
        });

        video.src = URL.createObjectURL(file);

        try {
          const duration = await durationPromise;
          if (duration > 10) {
            alert('Video must be less than 10 seconds long. Please choose a shorter video.');
            return;
          }
        } catch (error) {
          console.error('Error checking video duration:', error);
          alert('Unable to process the video. Please try a different file.');
          return;
        }
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      const selectedMenuItem = images.find(image => image.key === selectedMenuItemKey);
      
      if (!selectedMenuItem) {
        alert('Selected menu item not found.');
        return;
      }
      const generateRandomString = () => Math.random().toString(36).substring(2, 8);

      // Construct the new key with the same rank as the existing menu item and add the visibility field
    // Generate a 6-digit random string
        const randomString = generateRandomString();

        // Construct the new key with the same rank as the existing menu item, add the visibility field, and append the random string
        const newKey = selectedMenuItem.key.replace(/\.[^/.]+$/, "").replace(/_user.*$/, "").replace(/^(([^_]+_){3}[^_]+)/, "$1_hide_") + `_${randomString}.${fileExtension}`;

      const params = {
        Bucket: 'picturemenu',
        Key: newKey,
        Body: file,
        ContentType: file.type || 'application/octet-stream'
      };

      try {
        await s3.putObject(params).promise();

        // Update MongoDB with the new user-uploaded item
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        const user = await app.logIn(Realm.Credentials.anonymous());
        const mongodb = user.mongoClient('mongodb-atlas');
        const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

        const newMenuItem = {
          menuItemName: selectedMenuItem.name,
          showOrHide: 'hide',
          keyNameForS3: newKey,
          fileType: file.type,
          category: selectedMenuItem.category,
          sequenceWithinCategory: selectedMenuItem.rank,
          sequenceWithinIdenticalMenuItemName: 0,
        };

        await restaurantsCollection.updateOne(
          { restaurantCodeNumber: parseInt(inputPrefix) },
          { 
            $push: { menuItems: newMenuItem },
            $set: { updatedAt: new Date() }
          }
        );



        alert('Thank you for sharing your foodie moment! We will review it and approve it soon!');
        await retrieveAllImagesFromARestaurant(inputPrefix);
        setShowUploadForm(false);
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to upload media: ' + err.message);
      }
    } else {
      alert('Please provide a valid image or video file and select a menu item.');
    }
  };

  const toggleVisibility = async (key) => {
    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      const restaurant = await restaurantsCollection.findOne(
        { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key }
      );

      if (!restaurant) {
        throw new Error('Restaurant or menu item not found');
      }

      const menuItem = restaurant.menuItems.find(item => item.keyNameForS3 === key);
      const newVisibility = menuItem.showOrHide === 'show' ? 'hide' : 'show';

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key },
        { 
          $set: { 
            "menuItems.$.showOrHide": newVisibility,
            updatedAt: new Date()
          }
        }
      );

      alert('Visibility changed successfully');
      await retrieveAllImagesFromARestaurant(inputPrefix);
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to change visibility: ' + err.message);
    }
  };

  const handleCategoryNameChange = (index, newName) => {
    setCategories(prevCategories => {
      const updatedCategories = [...prevCategories];
      updatedCategories[index] = { ...updatedCategories[index], name: newName };
      return updatedCategories;
    });
  };

  const saveCategories = async () => {
    setLoading(true);
    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      const categoriesObject = categories.reduce((acc, category) => {
        acc[category.name] = category.order;
        return acc;
      }, {});

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix) },
        { 
          $set: { 
            categories: categoriesObject,
            updatedAt: new Date()
          }
        }
      );

      alert('Categories updated successfully');
      setEditingCategories(false);
      await retrieveAllImagesFromARestaurant(inputPrefix);
    } catch (err) {
      console.error('Error updating categories:', err);
      alert('Failed to update categories: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event, key) => {
    const newCategory = event.target.value;
    setTempCategories(prev => ({...prev, [key]: newCategory}));
  };


  const handleSaveCategory = async (key) => {
    const newCategory = tempCategories[key] || '';
    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');
  
      // First, check if the category already exists
      const restaurant = await restaurantsCollection.findOne(
        { restaurantCodeNumber: parseInt(inputPrefix) }
      );
  
      let categoryExists = false;
      let newSequenceOrder = 1;
  
      if (restaurant && restaurant.categories) {
        categoryExists = restaurant.categories.some(cat => cat.category === newCategory);
        newSequenceOrder = restaurant.categories.length + 1;
      }
  
      // If the category doesn't exist, add it
      if (!categoryExists) {
        await restaurantsCollection.updateOne(
          { restaurantCodeNumber: parseInt(inputPrefix) },
          {
            $push: { 
              categories: { category: newCategory, sequenceOrder: newSequenceOrder }
            },
            $set: { updatedAt: new Date() }
          },
          { upsert: true }
        );
      }
  
      // Update the menu item with the new category
      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key },
        {
          $set: {
            "menuItems.$.category": newCategory,
            updatedAt: new Date()
          }
        }
      );
  
      alert('Category changed successfully');
      setTempCategories(prev => {
        const newTemp = {...prev};
        delete newTemp[key];
        return newTemp;
      });
      await retrieveAllImagesFromARestaurant(inputPrefix);
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to change category: ' + err.message);
    }
  };

// Modify the handleCategoryOrderChange function
const handleCategoryOrderChange = (event, categoryName) => {
  const newOrder = parseInt(event.target.value);
  setTempCategoryOrders(prev => ({...prev, [categoryName]: newOrder}));
};


// Modify the handleSaveCategoryOrder function
const handleSaveCategoryOrder = async (categoryName) => {
  try {
    const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
    const user = await app.logIn(Realm.Credentials.anonymous());
    const mongodb = user.mongoClient('mongodb-atlas');
    const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

    const newOrder = tempCategoryOrders[categoryName] || categories.find(cat => cat.name === categoryName).order;

    await restaurantsCollection.updateOne(
      { restaurantCodeNumber: parseInt(inputPrefix), "categories.category": categoryName },
      { 
        $set: { 
          "categories.$.sequenceOrder": newOrder,
          updatedAt: new Date()
        }
      }
    );

    setCategories(prevCategories => 
      prevCategories.map(cat => 
        cat.name === categoryName ? {...cat, order: newOrder} : cat
      )
    );

    setTempCategoryOrders(prev => {
      const newTemp = {...prev};
      delete newTemp[categoryName];
      return newTemp;
    });

    alert('Category order updated successfully');
    await retrieveAllImagesFromARestaurant(inputPrefix);
  } catch (err) {
    console.error('Error:', err);
    alert('Failed to update category order: ' + err.message);
  }
};


  const handleDeleteCategory = async (categoryName) => {
    if (window.confirm(`Are you sure you want to delete the category "${categoryName}"?`)) {
      try {
        const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
        const user = await app.logIn(Realm.Credentials.anonymous());
        const mongodb = user.mongoClient('mongodb-atlas');
        const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');
  
        await restaurantsCollection.updateOne(
          { restaurantCodeNumber: parseInt(inputPrefix) },
          { 
            $pull: { categories: { category: categoryName } },
            $set: { 
              "menuItems.$[elem].category": "Uncategorized",
              updatedAt: new Date()
            }
          },
          {
            arrayFilters: [{ "elem.category": categoryName }],
            multi: true
          }
        );
  
        // Update local state
        setCategories(prevCategories => prevCategories.filter(cat => cat.name !== categoryName));
        setImages(prevImages => prevImages.map(img => 
          img.category === categoryName ? {...img, category: "Uncategorized"} : img
        ));
  
        alert('Category deleted successfully');
        await retrieveAllImagesFromARestaurant(inputPrefix);
      } catch (err) {
        console.error('Error deleting category:', err);
        alert('Failed to delete category: ' + err.message);
      }
    }
  };

  
  const renderMedia = (image, sameNameImages) => {
    // Filter visible images individually
    const visibleImages = sameNameImages.filter(img => img.showOrHide !== 'hide' || showHidden);
    
    if (visibleImages.length === 0) {
      return null;
    }
  
    const menuItemName = image.key.split('_')[3].replace(/-/g, ' ');
  
    let currentIndex = currentImageIndices[menuItemName] || 0;
    
    // Ensure currentIndex is within bounds of visible images
    currentIndex = currentIndex % visibleImages.length;
    const currentImage = visibleImages[currentIndex];
  
    const handleSwipe = (direction) => {
      const newIndex = (currentIndex + direction + visibleImages.length) % visibleImages.length;
      setCurrentImageIndices(prev => ({...prev, [menuItemName]: newIndex}));
    };
  
    const mediaElement = currentImage.type && currentImage.type.startsWith('image/') ? (
      <img 
        key={currentImage.key}
        className="media-item" 
        src={currentImage.src} 
        alt={currentImage.name} 
        style={{ opacity: activeImage === currentImage.key ? 0.5 : 1 }}
      />
    ) : currentImage.type && currentImage.type.startsWith('video/') ? (
      <video
        key={currentImage.key}
        className="media-item"
        ref={videoRef}
        muted
        loop
        autoPlay
        playsInline
        src={currentImage.src}
        alt={currentImage.name}
        style={{ opacity: activeImage === currentImage.key ? 0.5 : 1 }}
      />
    ) : (
      <p key={currentImage.key}>Unsupported file type: {currentImage.type || 'unknown'}</p>
    );
  
    const handleClick = (direction) => {
      handleSwipe(direction);
    };
  
    const displayName = menuItemName.replace(/\.[^/.]+$/, ""); // Remove file extension for display
  
    return (
      <div>
        <div 
          className="media-container" 
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={() => handleTouchEnd(handleSwipe)}
        >
          {visibleImages.length > 1 && (
            <div 
              className="click-area left" 
              onClick={() => handleClick(-1)}
            ></div>
          )}
          {mediaElement}
          {visibleImages.length > 1 && (
            <div 
              className="click-area right" 
              onClick={() => handleClick(1)}
            ></div>
          )}
          <div className="menu-item-name">{displayName}</div>
          {currentImage.showOrHide === 'hide' && <div className="hidden-indicator">Hidden</div>}
        </div>
        {visibleImages.length > 1 && (
          <div className="dot-indicators">
            {visibleImages.map((_, index) => (
              <span 
                key={index} 
                className={`dot ${index === currentIndex ? 'active' : ''}`}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = (handleSwipe) => {
    if (touchStartX - touchEndX > 50) {
      // Swipe left
      handleSwipe(1);
    } else if (touchEndX - touchStartX > 50) {
      // Swipe right
      handleSwipe(-1);
    }
    // Reset values
    touchStartX = 0;
    touchEndX = 0;
  };

  
  const handleCleanUp = async () => {
    setLoading(true);
    let processedCount = 0;
    let updatedCount = 0;
    let deletedCount = 0;

    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      console.log('Starting cleanup process...');

      let continuationToken = null;
      do {
        const listParams = { 
          Bucket: 'picturemenu',
          MaxKeys: 1000,
          ContinuationToken: continuationToken
        };
        console.log('Fetching S3 objects...');
        const data = await s3.listObjectsV2(listParams).promise();

        for (const object of data.Contents) {
          const keyName = object.Key;
          processedCount++;

          console.log(`Processing S3 object: ${keyName}`);

          const parts = keyName.split('_');
          if (parts.length < 4) {
            console.log(`Skipping invalid key: ${keyName}`);
            continue;
          }

          const restaurantCodeNumber = parseInt(parts[0]);
          const menuItemName = parts[3].replace(/-/g, ' ').replace(/\.[^/.]+$/, "");
          const showOrHide = parts[4] || 'show';
          const fileType = keyName.split('.').pop().toLowerCase();

          let category = 'Uncategorized';
          let sequenceWithinCategory = 0;
          for (let i = 5; i < parts.length; i++) {
            if (parts[i].startsWith('CN_')) {
              category = parts[i + 2].replace(/-/g, ' ');
              sequenceWithinCategory = parseInt(parts[i + 1]);
              break;
            }
          }

          const restaurant = await restaurantsCollection.findOne({ restaurantCodeNumber });

          if (restaurant) {
            const existingMenuItem = restaurant.menuItems.find(item => item.keyNameForS3 === keyName);

            if (existingMenuItem) {
              // Update existing menu item
              console.log(`Updating existing menu item: ${menuItemName}`);
              await restaurantsCollection.updateOne(
                { restaurantCodeNumber, "menuItems.keyNameForS3": keyName },
                { 
                  $set: { 
                    "menuItems.$": {
                      menuItemName,
                      showOrHide,
                      keyNameForS3: keyName,
                      fileType,
                      category,
                      sequenceWithinCategory,
                      sequenceWithinIdenticalMenuItemName: 0,
                    },
                    updatedAt: new Date()
                  }
                }
              );
              updatedCount++;
            } else {
              // Add new menu item
              console.log(`Adding new menu item: ${menuItemName}`);
              await restaurantsCollection.updateOne(
                { restaurantCodeNumber },
                { 
                  $push: { 
                    menuItems: {
                      menuItemName,
                      showOrHide,
                      keyNameForS3: keyName,
                      fileType,
                      category,
                      sequenceWithinCategory,
                      sequenceWithinIdenticalMenuItemName: 0,
                    }
                  },
                  $set: { updatedAt: new Date() }
                }
              );
              updatedCount++;
            }
          } else {
            console.log(`Restaurant not found for code: ${restaurantCodeNumber}`);
          }
        }

        continuationToken = data.NextContinuationToken;
      } while (continuationToken);

      // Remove menu items that don't exist in S3
      console.log('Removing menu items not found in S3...');
      const allRestaurants = await restaurantsCollection.find({}).toArray();
      for (const restaurant of allRestaurants) {
        const validMenuItems = await Promise.all(restaurant.menuItems.map(async (item) => {
          try {
            await s3.headObject({ Bucket: 'picturemenu', Key: item.keyNameForS3 }).promise();
            return item;
          } catch (error) {
            if (error.code === 'NotFound') {
              console.log(`Removing menu item: ${item.menuItemName}`);
              deletedCount++;
              return null;
            }
            throw error;
          }
        }));

        const filteredMenuItems = validMenuItems.filter(item => item !== null);
        if (filteredMenuItems.length !== restaurant.menuItems.length) {
          await restaurantsCollection.updateOne(
            { restaurantCodeNumber: restaurant.restaurantCodeNumber },
            { 
              $set: { 
                menuItems: filteredMenuItems,
                updatedAt: new Date()
              }
            }
          );
        }
      }

      console.log('Cleanup process completed.');
      alert(`Cleanup completed successfully!\nProcessed: ${processedCount}\nUpdated/Added: ${updatedCount}\nDeleted: ${deletedCount}`);
    } catch (error) {
      console.error('Error during cleanup:', error);
      alert(`An error occurred during cleanup. Check console for details.`);
    } finally {
      setLoading(false);
    }
  };

  const updateKeyNameInS3AndMongoDB = async (oldKey, newKey, restaurantCodeNumber) => {
    try {
      // Update in S3
      await s3.copyObject({
        Bucket: 'picturemenu',
        CopySource: `picturemenu/${oldKey}`,
        Key: newKey
      }).promise();

      await s3.deleteObject({
        Bucket: 'picturemenu',
        Key: oldKey
      }).promise();

      // Update in MongoDB
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: restaurantCodeNumber, "menuItems.keyNameForS3": oldKey },
        { 
          $set: { 
            "menuItems.$.keyNameForS3": newKey,
            updatedAt: new Date()
          }
        }
      );

      console.log(`Updated key from ${oldKey} to ${newKey} in S3 and MongoDB`);
    } catch (error) {
      console.error('Error updating keyName:', error);
      throw error;
    }
  };


  const handleSequenceWithinCategoryChange = (event, key) => {
    const newSequence = parseInt(event.target.value);
    setImages(prevImages => 
      prevImages.map(img => 
        img.key === key ? {...img, sequenceWithinCategory: newSequence} : img
      )
    );
  };

  const handleSaveSequenceWithinCategory = async (key) => {
    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      const image = images.find(img => img.key === key);
      if (!image) {
        throw new Error('Image not found');
      }

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key },
        { 
          $set: { 
            "menuItems.$.sequenceWithinCategory": image.sequenceWithinCategory,
            updatedAt: new Date()
          }
        }
      );

      alert('Sequence within category updated successfully');
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to update sequence within category: ' + err.message);
    }
  };

  const handleSequenceWithinIdenticalMenuItemNameChange = (event, key) => {
    const newSequence = parseInt(event.target.value);
    setImages(prevImages => 
      prevImages.map(img => 
        img.key === key ? {...img, sequenceWithinIdenticalMenuItemName: newSequence} : img
      )
    );
  };

  const handleSaveSequenceWithinIdenticalMenuItemName = async (key) => {
    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      const image = images.find(img => img.key === key);
      if (!image) {
        throw new Error('Image not found');
      }

      await restaurantsCollection.updateOne(
        { restaurantCodeNumber: parseInt(inputPrefix), "menuItems.keyNameForS3": key },
        { 
          $set: { 
            "menuItems.$.sequenceWithinIdenticalMenuItemName": image.sequenceWithinIdenticalMenuItemName,
            updatedAt: new Date()
          }
        }
      );

      alert('Sequence within identical menu item name updated successfully');
    } catch (err) {
      console.error('Error:', err);
      alert('Failed to update sequence within identical menu item name: ' + err.message);
    }
  };

  const groupedImages = images.reduce((acc, image) => {
    if (!acc[image.name]) {
      acc[image.name] = [];
    }
    acc[image.name].push(image);
    return acc;
  }, {});

  const handleNewRestaurantChange = (e) => {
    const { name, value, files } = e.target;
    setNewRestaurantData(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const handleCreateNewRestaurant = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID });
      const user = await app.logIn(Realm.Credentials.anonymous());
      const mongodb = user.mongoClient('mongodb-atlas');
      const restaurantsCollection = mongodb.db('picturemenu').collection('restaurants');

      // Upload file to S3
      const file = newRestaurantData.file;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const s3Key = `${newRestaurantData.restaurantCodeNumber}_${newRestaurantData.restaurantName.replace(/ /g, '-')}_1_${newRestaurantData.menuItemName.replace(/ /g, '-')}.${fileExtension}`;

      await s3.putObject({
        Bucket: 'picturemenu',
        Key: s3Key,
        Body: file,
        ContentType: file.type
      }).promise();

      // Create new restaurant document
      const newRestaurant = {
        restaurantCodeNumber: parseInt(newRestaurantData.restaurantCodeNumber),
        restaurantName: newRestaurantData.restaurantName,
        categories: [{
          category: newRestaurantData.category,
          sequenceOrder: parseInt(newRestaurantData.categorySequenceOrder)
        }],
        menuItems: [{
          menuItemName: newRestaurantData.menuItemName,
          showOrHide: 'show',
          keyNameForS3: s3Key,
          fileType: file.type,
          category: newRestaurantData.category,
          sequenceWithinCategory: 1,
          sequenceWithinIdenticalMenuItemName: 1,
        }],
      };

      await restaurantsCollection.insertOne(newRestaurant);

      alert('New restaurant created successfully!');
      setShowNewRestaurantForm(false);
      setNewRestaurantData({
        restaurantCodeNumber: '',
        restaurantName: '',
        category: 'Uncategorized',
        categorySequenceOrder: '10',
        menuItemName: '',
        file: null,
      });
      await retrieveAllImagesFromARestaurant(newRestaurantData.restaurantCodeNumber);
    } catch (err) {
      console.error('Error creating new restaurant:', err);
      alert('Failed to create new restaurant: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
  <div className="search-container">
    <form onSubmit={handleSearch}>
      <input
        type="text"
        placeholder="Search categories..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </form>
  </div>



        {editEnabled && (
          <button 
            className="new-restaurant-button" 
            onClick={() => setShowNewRestaurantForm(true)}
          >
            Create New Restaurant
          </button>
        )}

{showNewRestaurantForm && (
          <div className="new-restaurant-form">
            <h2>Create New Restaurant</h2>
            <form onSubmit={handleCreateNewRestaurant}>
              <input
                type="text"
                placeholder="Restaurant Code Number"
                value={newRestaurantData.restaurantCodeNumber}
                onChange={(e) => setNewRestaurantData({...newRestaurantData, restaurantCodeNumber: e.target.value})}
                required
              />
              <input
                type="text"
                placeholder="Restaurant Name"
                value={newRestaurantData.restaurantName}
                onChange={(e) => setNewRestaurantData({...newRestaurantData, restaurantName: e.target.value})}
                required
              />
              <input
                type="text"
                placeholder="Category"
                value={newRestaurantData.category}
                onChange={(e) => setNewRestaurantData({...newRestaurantData, category: e.target.value})}
                required
              />
              <input
                type="number"
                placeholder="Category Sequence Order"
                value={newRestaurantData.categorySequenceOrder}
                onChange={(e) => setNewRestaurantData({...newRestaurantData, categorySequenceOrder: e.target.value})}
                required
              />
              <input
                type="text"
                placeholder="Menu Item Name"
                value={newRestaurantData.menuItemName}
                onChange={(e) => setNewRestaurantData({...newRestaurantData, menuItemName: e.target.value})}
                required
              />
              <input
                type="file"
                onChange={(e) => setNewRestaurantData({...newRestaurantData, file: e.target.files[0]})}
                required
              />
              <button type="submit" disabled={loading}>
                {loading ? 'Creating...' : 'Create Restaurant'}
              </button>
              <button type="button" onClick={() => setShowNewRestaurantForm(false)}>
                Cancel
              </button>
            </form>
          </div>
        )}


        {!hideForm && !hideEverything && (
          <form onSubmit={handleSubmit}>
            <label htmlFor="inputPrefix">Enter restaurant code (1, 2, 3 etc for now... QR code system also works!):</label>
            <input
              id="inputPrefix"
              type="text"
              value={inputPrefix}
              onChange={(e) => setInputPrefix(e.target.value)}
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'SHOW ME FOOD'}
            </button>
          </form>
        )}
        
        {nameOfRestaurant !== '' && (
          <>
            <h1>{nameOfRestaurant}</h1>
            {!isNoUpdateRestaurant && (
              <div className="upload-section">

                <button className="add-photo-button" onClick={handleAddPhotoClick}>
                  <span className={`plus-icon ${showUploadForm ? 'minus' : ''}`}>
                      {showUploadForm ? '-' : '+'}
                  </span>
                </button>
                <br/>
              </div>
            )}
          </>
        )}


        <div className={`images ${images.length > 20 ? 'two-columns' : ''}`}>
        {filteredCategories.map((category) => (
            <div key={category.order} className="category-section">
<h2 className="category-name">
  {highlightMatch(category.name, searchTerm)}
  {editEnabled && (
    <button onClick={() => handleDeleteCategory(category.name)} className="delete-category-btn">
      Delete Category
    </button>
  )}
</h2>              {Object.entries(groupedImages)
                .filter(([, sameNameImages]) => sameNameImages[0].category === category.name)
                .filter(([, sameNameImages]) => editEnabled || !sameNameImages[0].isHidden)
                .map(([name, sameNameImages]) => (
                  <div key={name} className="image-item"
                       onDrop={(event) => handleDrop(event, sameNameImages[0].key)}
                       onDragOver={handleDragOver}>
                    {renderMedia(sameNameImages[0], sameNameImages)}
                    {activeImage === sameNameImages[0].key && editEnabled && <div className="overlay">
                      Drop media here to replace
                    </div>}
                    {editEnabled && (
                      <div className="image-controls">
<input
  type="text"
  value={tempNames[sameNameImages[0].key] || sameNameImages[0].fullName}
  onChange={(event) => {
    setTempNames({...tempNames, [sameNameImages[0].key]: event.target.value});
  }}
/>
                        <button onClick={() => handleSaveName(sameNameImages[0].key)}>Save Name</button>
                        
                        <br/>
                        <br/><input
                          type="text"
                          value={tempCategories[sameNameImages[0].key] || category.name}
                          onChange={(event) => handleCategoryChange(event, sameNameImages[0].key)}
                          placeholder="Enter category"
                        />
                        
                        <button onClick={() => handleSaveCategory(sameNameImages[0].key)}>Save Category</button>
                        <br/>
                        <br/>
                        <input
  type="number"
  value={tempCategoryOrders[category.name] !== undefined ? tempCategoryOrders[category.name] : category.order}
  onChange={(event) => handleCategoryOrderChange(event, category.name)}
/>
<button onClick={() => handleSaveCategoryOrder(category.name)}>Save Category Order</button>
<br/>
<br/>
<input
  type="number"
  value={sameNameImages[0].sequenceWithinCategory}
  onChange={(event) => handleSequenceWithinCategoryChange(event, sameNameImages[0].key)}
/>
<button onClick={() => handleSaveSequenceWithinCategory(sameNameImages[0].key)}>Save order within category</button><br/>
                        <br/>
                        <input
                          type="number"
                          value={sameNameImages[0].sequenceWithinIdenticalMenuItemName}
                          onChange={(event) => handleSequenceWithinIdenticalMenuItemNameChange(event, sameNameImages[0].key)}
                        />
                        <button onClick={() => handleSaveSequenceWithinIdenticalMenuItemName(sameNameImages[0].key)}>Save order within same menu item name</button>
                        <br/>
                        <br/>
                        <button onClick={() => toggleVisibility(sameNameImages[0].key)}>
                          {sameNameImages[0].isHidden ? 'Show' : 'Hide'}
                        </button>
                        <button className="delete-btn" onClick={() => handleDeleteImage(sameNameImages[0].key)}>Delete</button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ))}
<div className="uncategorized-section">
  {Object.entries(groupedImages)
    .filter(([, sameNameImages]) => !categories.some(cat => cat.name === sameNameImages[0].category))
    .filter(([, sameNameImages]) => editEnabled || !sameNameImages[0].isHidden)
    .sort(([, a], [, b]) => {
      const seqA = parseInt(a[0].sequenceWithinCategory, 10);
      const seqB = parseInt(b[0].sequenceWithinCategory, 10);
      return seqA - seqB;
    })
    .map(([name, sameNameImages]) => (
      <div key={name} className="image-item"
           onDrop={(event) => handleDrop(event, sameNameImages[0].key)}
           onDragOver={handleDragOver}>
        {renderMedia(sameNameImages[0], sameNameImages)}
        {activeImage === sameNameImages[0].key && editEnabled && <div className="overlay">
          Drop media here to replace
        </div>}
        {editEnabled && (
                    <div className="image-controls">
<input
  type="text"
  value={tempNames[sameNameImages[0].key] || sameNameImages[0].fullName}
  onChange={(event) => {
    setTempNames({...tempNames, [sameNameImages[0].key]: event.target.value});
  }}
/>
                      <button onClick={() => handleSaveName(sameNameImages[0].key)}>Save Name</button>
                      <br/>
                      <br/>
                      <input
                        type="text"
                        value={tempCategories[sameNameImages[0].key] || ''}
                        onChange={(event) => handleCategoryChange(event, sameNameImages[0].key)}
                        placeholder="Enter category"
                      />
                      <button onClick={() => handleSaveCategory(sameNameImages[0].key)}>Save Category</button>
<input
  type="number"
  value={sameNameImages[0].order}
  onChange={(event) => handleCategoryOrderChange(event, sameNameImages[0].name)}
/>
<button onClick={() => handleSaveCategoryOrder(sameNameImages[0].name)}>Save Category Order</button>

<br/>
<br/>
                      
                      
                      
                      <button onClick={() => toggleVisibility(sameNameImages[0].key)}>
                        {sameNameImages[0].isHidden ? 'Show' : 'Hide'}
                      </button>
                      <br/>
                      <br/>
                      <input
  type="number"
  value={sameNameImages[0].sequenceWithinCategory}
  onChange={(event) => handleSequenceWithinCategoryChange(event, sameNameImages[0].key)}
/>
<button onClick={() => handleSaveSequenceWithinCategory(sameNameImages[0].key)}>Save order within category</button>
                      <br/>
                      <br/>
                      <input
                        type="number"
                        value={sameNameImages[0].sequenceWithinIdenticalMenuItemName}
                        onChange={(event) => handleSequenceWithinIdenticalMenuItemNameChange(event, sameNameImages[0].key)}
                      />
                     
                     
                 <button onClick={() => handleSaveSequenceWithinIdenticalMenuItemName(sameNameImages[0].key)}>Save order within same menu item name</button>
                 <br/>
                 <br/>
                      <button onClick={() => handleDeleteImage(sameNameImages[0].key)}>Delete</button>

                    </div>

                    
                  )}
                </div>
              ))}
          </div>
        </div>

        {editEnabled && (
          <form onSubmit={handleNewMediaUpload} className="new-media-form">
            <h2>Add New Menu Item</h2>
            <div className="form-group">
              <label htmlFor="menuItemName">Menu Item Name:</label>
              <input id="menuItemName" name="menuItemName" type="text" required />
            </div>

            <div className="form-group">
              <label htmlFor="newMediaFile">Media File:</label>
              <input id="newMediaFile" name="newMediaFile" type="file" accept="image/*,video/*" required />
            </div>

            <div className="form-group">
              <label htmlFor="category">Category:</label>
              <input id="category" name="category" type="text" placeholder="Uncategorized" />
            </div>

            <div className="form-group">
              <label htmlFor="sequenceWithinCategory">Sequence Within Category:</label>
              <input id="sequenceWithinCategory" name="sequenceWithinCategory" type="number" min="0" defaultValue="0" />
            </div>

            <div className="form-group">
              <label htmlFor="sequenceWithinIdenticalMenuItemName">Sequence Within Identical Menu Item Name:</label>
              <input id="sequenceWithinIdenticalMenuItemName" name="sequenceWithinIdenticalMenuItemName" type="number" min="0" defaultValue="0" />
            </div>

            <div className="form-group">
              <label htmlFor="categoryNewSequence">Category's New Sequence:</label>
              <input id="categoryNewSequence" name="categoryNewSequence" type="number" min="1" defaultValue="1" />
            </div>
            <button type="submit">Upload New Menu Item</button>
          </form>
        )}


        <br/>


        
        {showUploadForm && (
          <div className="upload-form-overlay" onClick={handleAddPhotoClick}>
            <div className="upload-form" ref={uploadFormRef} onClick={(e) => e.stopPropagation()}>
              <h2>Share Your Foodie Moment</h2>
              <form onSubmit={handleUserUpload}>
                <div className="form-group">
                  <label htmlFor="menuItemSelect">Which dish inspired you?</label>
                  <select id="menuItemSelect" name="menuItemSelect" required>
                    {[...new Set(images.map(image => image.name))].map((uniqueName, index) => {
                      const image = images.find(img => img.name === uniqueName);
                      return (
                        <option key={index} value={image.key}>{uniqueName}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    id="userMediaFile"
                    name="userMediaFile"
                    type="file"
                    accept="image/*,video/*"
                    required
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  {!selectedFile ? (
                    <button type="button" className="choose-file-btn" onClick={() => fileInputRef.current.click()}>
                      Choose Your Masterpiece
                    </button>
                  ) : (
                    <div className="selected-file-preview">
                      <img 
                        src={URL.createObjectURL(selectedFile)} 
                        alt="Selected" 
                        style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }} 
                        onClick={() => fileInputRef.current.click()}
                      />
                      <button type="submit" className="upload-btn">Share the Deliciousness</button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}

        <br/>
        <br/>


      </header>
    </div>
  );  
}


export default App;